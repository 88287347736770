import { useState } from 'react';
import { useSelector } from 'react-redux';

import FilterCustomEmails from './FilterCustomEmails/FilterCustomEmails';

export const HeaderCustomEmails = ({ handleSubmit, headers, setHeaders, idCustomTable }) => {
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  return (
    <div className="container-info container-search-dashboard pb-0 mb-0 bg-white rounded px-4 pt-4 mx-auto">
      <div className="d-flex mt-2">
        <div className="w-100">
          <FilterCustomEmails handleSubmit={handleSubmit} showMoreFilters={showMoreFilters} />
        </div>
      </div>
    </div>
  );
};
