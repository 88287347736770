import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import BackgroundTop from '../BackgroundTop/BackgroundTop';
import { t } from '../../i18n/i18n';
import arrowRight from '../../assets/sentScreening/arrowRightWhite.svg';
import { getLastEmail, saveTemplateEmail } from '../../API/services/alkymersService';
import { setSubjectEmail, setTemplate } from '../../redux/actions/screeningActions';
import { templateDefault } from '../../constants/emailTemplate';
import 'react-quill/dist/quill.snow.css';
import './EditEmailTemplate.scss';
import useCreateSearch from '../../hooks/useCreateSearch';
import ModalCustom from '../ModalCustom/ModalCustom';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import { Window } from '../../utils/window';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';
import SafeContainer from '../SafeContainer/SafeContainer';
import EmailTagsInput from './EmailTagsInput';
import useTranslations from '../../hooks/useTranslations';

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const EditEmailTemplate = ({
  goBack,
  handleSendRequest,
  viewInfo = true,
  emailDashboard = false,
  emails = [],
}) => {
  const { template, newSearch, subjectEmail } = useSelector((state) => state.screenings);
  const { step } = useCreateSearch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState('');
  const [subject, setSubject] = useState(subjectEmail.subjectEmail);
  const [buttonText, setButtonText] = useState(t('GO_TO_PLATFORM'));
  const regex = new RegExp(/\{(.+?)\}/g);
  const [result, setResult] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [emailsToSend, setEmailsToSend] = useState(emails);
  const { key } = useTranslations();

  useEffect(() => {
    setSubject(subjectEmail.subjectEmail);
  }, [subjectEmail]);

  const mapObj = {
    '{{firstName}}': t('EMAIL_TO_SEND_FIRSTNAME'),
    '{{lastName}}': t('EMAIL_TO_SEND_LASTNAME'),
    '{{companyName}}': t('ALKEMY'),
    '{{score}}': t('EMAIL_TO_SEND_SCORE'),
    '{{email}}': 'alkemy@test.com',
  };
  useEffect(() => {
    const url = history.location.pathname;
    if (Window() && url !== '/create-template') {
      window.onpopstate = () => {
        history.goForward();
      };
    }
    return () => {
      if (Window()) {
        window.onpopstate = () => {};
      }
    };
  }, []);

  useEffect(() => {
    if (!emailDashboard) {
      if (!template?.html?.length) {
        const recruitSessionStageId = newSearch?.stages[0]?.id;
        getLastEmail(recruitSessionStageId)
          .then((res) => {
            if (Object.keys(res).length && res?.html?.length) {
              const html = res.html?.replace(
                '{{HTML}}',
                res?.translations ? res?.translations[key]?.HTML : ''
              );
              const subject = res.subject?.replace(
                'SUBJECT',
                res?.translations ? res?.translations[key]?.SUBJECT : ''
              );
              setValue(html);
              if (res?.buttonText) setButtonText(res?.buttonText);
              dispatch(setSubjectEmail({ subjectEmail: subject ?? '' }));
              dispatch(setTemplate({ template: html, id: res.id }));
            } else {
              setValue(templateDefault(getLocalStorageWorkspace()?.companyName));
              dispatch(
                setTemplate({
                  template: templateDefault(getLocalStorageWorkspace()?.companyName),
                })
              );
              dispatch(setSubjectEmail({ subjectEmail: '' }));
            }
          })
          .catch((error) => {
            setValue(templateDefault(getLocalStorageWorkspace()?.companyName));
            dispatch(
              setTemplate({
                template: templateDefault(getLocalStorageWorkspace()?.companyName),
              })
            );
            dispatch(setSubjectEmail({ subjectEmail: '' }));
          });
      } else {
        setValue(template);
      }
    }
    dispatch(setSubjectEmail({ subjectEmail: '' }));
  }, []);

  useEffect(() => {
    document.getElementById('divCustomPreview').innerHTML = value.replace(
      /{{firstName}}|{{lastName}}|{{score}}|{{companyName}}|{{email}}/g,
      (matched) => mapObj[matched]
    );
    if (
      value.match(regex) !== null &&
      !!value.match(regex).length &&
      JSON.stringify(value.match(regex)) !== JSON.stringify(result)
    )
      setResult(value.match(regex));
  }, [value]);

  const handleCustomEmail = async () => {
    TrackEvent('search-send-email');
    if (subject !== '') {
      const recruitSessionStageId = newSearch?.stages[0]?.id;
      await saveTemplateEmail(value, subject, recruitSessionStageId, buttonText).then((res) => {
        dispatch(setTemplate({ template: res.html, id: res.id }));
        dispatch(setSubjectEmail({ subjectEmail: subject }));
      });
      setShowModal(true);
    } else {
      notification.open({
        message: t('SUBJECT_EMAIL_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };

  const handleGenericEmail = () => {
    TrackEvent('search-send-email');
    dispatch(setTemplate({ template: value }));
    if (subject !== '') {
      dispatch(setSubjectEmail({ subjectEmail: subject }));
      setShowModal(true);
    } else {
      notification.open({
        message: t('SUBJECT_EMAIL_ERROR'),
        icon: <i className="fas fa-times" style={{ color: 'red' }} />,
      });
    }
  };

  const handleSendEmail = async () => {
    TrackEvent('search-send-email-confirm');
    setShowModal(false);
    handleSendRequest(error, setLoading, emailsToSend);
  };

  return (
    <div data-testid="edit-email-template-component" className="w-100 mx-auto h-100">
      <ModalCustom
        title={
          emailDashboard
            ? t('CREATE_SEARCH_CLICK_SEND_SEARCH')
            : t('CREATE_SEARCH_CLICK_SEND_SCREENING')
        }
        subtitle={t('CREATE_SEARCH_CHANGE_DATA_QUESTION')}
        show={showModal}
        setShow={setShowModal}
        onOk={handleSendEmail}
        onOkDisabled={loading}
        onCancel={() => setShowModal(false)}
        classModal="modal-wrap-add-row"
        onCancelText={t('CREATE_SEARCH_CANCEL_SEARCH')}
        onOkText={emailDashboard ? t('SEND_CUSTOM_EMAIL_BTN') : t('SEND_SCREENING_EMAIL_BTN')}
        showCross={false}
        datatestidbuttonok="send-email-button"
      />
      <BackgroundTop
        title={`${t('CREATE_SEARCH_CAPSULE_TITLE')}${newSearch?.name && `: ${newSearch?.name}`}`}
        className="position-relative"
        titleClassname="text-title"
      />
      <div className="px-2">
        <div className="position-relative d-flex flex-column description-container p-container-email pt-4 bg-white rounded mx-auto justify-content-around px-4">
          {viewInfo && (
            <div className="d-flex justify-content-between align-items-start">
              {step <= 3 && (
                <span className="text-xs  text-muted">
                  {t('CREATE_SEARCH_PLACED_STEP').replace('{step}', step)}
                </span>
              )}
            </div>
          )}
          <h3 className=" font-weight-600 text-base my-3">{t('EMAIL_DASHBOARD_TITLE')}</h3>
          <h3 className=" font-weight-500 text-xs mt-2 gray-subtitle">
            {t('EMAIL_DASHBOARD_SUBTITLE')}
          </h3>
          <div className="d-flex flex-column flex-lg-row mt-3">
            <div className="mr-3 custom-mw-component d-flex flex-column justify-content-between">
              {emails?.length ? (
                <div className="mb-4">
                  <EmailTagsInput setEmails={setEmailsToSend} emails={emailsToSend} />
                </div>
              ) : null}
              <input
                type="text"
                data-testid="subject-input"
                value={subject}
                placeholder={t('EMAIL_TEMPLATE_PLACEHOLDER_SUBJECT')}
                className="text-xs  outline-none mb-2 unselected-border subject-input"
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
              {typeof window !== 'undefined' && ReactQuill && (
                <SafeContainer
                  theme="snow"
                  content={value}
                  style="d-flex flex-column justify-content-between flex-grow-1 mb-2"
                  modulesAllow={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ align: '' }, { align: 'center' }, { align: 'right' }],
                      ['link'],
                    ],
                  }}
                  handleChange={(val) => {
                    setValue(val);
                  }}
                />
              )}
              <div className="edit-mail-info-container rounded mt-3  my-3 mb-lg-0 py-2 px-3">
                {error && (
                  <div className="bg-danger text-white my-2 mr-3 p-2 rounded  text-xs">
                    {t('ATTRIBUTE_IS_INVALID')}
                  </div>
                )}
                {t('THE_FIELDS')}
                {emailDashboard ? (
                  <>
                    <span className="font-montserrat font-weight-bold">{' {{firstName}}, '}</span>
                    <span className="font-montserrat font-weight-bold">{' {{lastName}}, '}</span>
                    <span className="font-montserrat font-weight-bold">{' {{companyName}}, '}</span>
                    <span className="font-montserrat font-weight-bold">{' {{email}} '}</span>
                    {t('AND_CUSTOM')}
                    <span data-testid="score-span" className="font-montserrat font-weight-bold">
                      {' {{score}} '}
                    </span>
                    {t('TEXT_CREATE_TEMPLATE')}
                  </>
                ) : (
                  <>
                    <span className="font-montserrat font-weight-bold">{' {{firstName}}, '}</span>
                    <span className="font-montserrat font-weight-bold">{' {{lastName}} '}</span>
                    <span className="font-montserrat font-weight-bold">{' {{companyName}} '}</span>
                    {t('AND_CUSTOM')}
                    <span className="font-montserrat font-weight-bold">{' {{email}} '}</span>
                    {t('TEXT_CREATE_TEMPLATE')}
                  </>
                )}
              </div>
            </div>

            <div className="d-flex flex-column w-100  preview-email-custom-component">
              <p className=" font-weight-600 text-blue-principal text-base mb-0">{`${t(
                'PREVIEW'
              )}:`}</p>
              <div className="flex-grow-1 py-3 px-3 custom-overflow template-custom-shadow">
                {getLocalStorageWorkspace()?.companyLogo && (
                  <div className="text-center mb-4">
                    <img
                      data-testid="company-template-image"
                      className="picture-company-email"
                      src={getLocalStorageWorkspace()?.companyLogo || ''}
                      alt={t('LOGO_COMPANY_TITLE')}
                      title={t('LOGO_COMPANY_TITLE')}
                    />
                  </div>
                )}
                <div id="divCustomPreview">{value?.inner}</div>
                <div className="text-center">
                  <button
                    className="button-screening-continue px-4 border-0"
                    type="button"
                    data-testid="example-template-button"
                    onClick={() => {}}
                  >
                    <span className="button-screening-continue-text text-xs  font-weight-bold">
                      {buttonText}
                      <img src={arrowRight} alt={t('ICON_ARROW')} className="pl-2 mb-1" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <button
              data-testid="go-back-button"
              className="button-screening-continue px-4 py-1 rounded-0 my-3 mr-auto"
              type="button"
              onClick={() => {
                if (goBack) {
                  goBack();
                } else {
                  history.goBack();
                }
              }}
            >
              <span className="button-screening-continue-text  text-xs font-weight-bold">
                {t('CREATE_SEARCH_STAGE_BACK')}
              </span>
            </button>
            <button
              disabled={loading}
              data-testid="send-evaluation-button"
              className="button-screening-continue ml-auto px-4 py-1 rounded-0 my-3"
              type="button"
              onClick={emailDashboard ? handleGenericEmail : handleCustomEmail}
            >
              <span className="button-screening-continue-text  text-xs font-weight-bold">
                {t('SCREENING_SEND')}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEmailTemplate;
