import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Slider } from 'antd';

import search from '../../../assets/profile/search.svg';
import { t } from '../../../i18n/i18n';
import FiltersSkeleton from './FiltersSkeleton';
import './Search.scss';
import './FilterCustomEmails.scss';
import MultipleSelect from '../../MultipleSelect/MultipleSelect';
import DropDownArrow from '../../../assets/screening/DropDownArrow';
import { setCustomEmails } from '../../../redux/actions/customEmailsActions';
import setLanguage from '../../../utils/filters/setLanguage';
import { getScreenings } from '../../../API/services/screeningService';
import useFiltersDashboardSearch from '../../../hooks/useFiltersDashboardSearch';

const today = moment().format('YYYY-MM-DD');
const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
const lastWeek = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
const lastMonth = moment().subtract(1, 'months').format('YYYY-MM-DD');
const last3Month = moment().subtract(3, 'months').format('YYYY-MM-DD');

function FilterCustomEmails({ handleSubmit = () => {}, showMoreFilters }) {
  const optionsDate = [
    { id: 1, value: 1, label: t('YESTERDAY'), fromDate: yesterday, toDate: today },
    { id: 2, value: 2, label: t('LAST_WEEK'), fromDate: lastWeek, toDate: today },
    { id: 3, value: 3, label: t('LAST_MONTH'), fromDate: lastMonth, toDate: today },
    { id: 4, value: 4, label: t('LAST_3_MONTH'), fromDate: last3Month, toDate: today },
  ];
  const optionsLanguages = [
    { id: 'es', value: 'es', label: t('SPANISH') },
    { id: 'pt', value: 'pt', label: t('PORTUGUESE') },
    { id: 'en', value: 'en', label: t('ENGLISH') },
  ];

  const dispatch = useDispatch();
  const customEmails = useSelector((state) => state.customEmails.customEmailsPaginated);
  const [inputs, setInputs] = useState({ score: { min: 0, max: 100 } });
  const [selectOpened, setSelectOpened] = useState({});
  const [optionScreening, setOptionScreening] = useState([]);
  const [screeningLoading, setScreeningLoading] = useState(false);
  const { availableFilters } = useSelector((state) => state.profiles);
  const { currentWorkspace } = useSelector((state) => state?.auth);
  const { loadFilters } = useFiltersDashboardSearch('');
  const onChangeFilter = (filterToAdd) => {
    setInputs({ ...inputs, ...filterToAdd });
    handleSubmit(0, { ...customEmails?.filter, ...filterToAdd }, true);
  };

  const handleSearchScreening = async () => {
    try {
      setScreeningLoading(true);
      const response = await getScreenings(currentWorkspace);
      setOptionScreening(response);
    } catch (error) {}
    setScreeningLoading(false);
  };

  useEffect(() => {
    handleSearchScreening();
    loadFilters();
  }, []);

  useEffect(() => {
    const { workspaceIds, workspaceId, ...rest } = customEmails?.filter;
    if (!Object.keys(rest).length) {
      setInputs({ score: { min: 0, max: 100 }, search: '' });
    }
  }, [customEmails?.filter]);

  const hasFilter = useMemo(() => {
    const { workspaceIds, workspaceId, ...rest } = customEmails?.filter || {};
    return Object.keys(rest).length > 0;
  }, [customEmails?.filter]);
  const cleanFilters = async () => {
    await handleSubmit(0, {}, true);
  };

  return (
    <>
      <div className="profile-search-container w-100">
        {customEmails.loading ? (
          <FiltersSkeleton dashboard />
        ) : (
          <div className="small" data-tour-id="profile-filters">
            <div className="w-100">
              <div className="d-flex align-items-center w-100 filters-container-gap flex-wrap justify-content-between">
                <div
                  data-testid="dashboard-search-form"
                  className=" Navbar-container-input border border-maya-blue  d-flex align-items-center py-1"
                >
                  <img src={search} alt={t('SEARCH_ALT')} className="h-75 ml-2" />
                  <input
                    className="border-0  input px-2 text-xs input-search-dashboard w-100 rounded-0"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onChangeFilter({ search: e.target.value });
                      }
                    }}
                    onChange={(e) => {
                      setInputs({ ...inputs, search: e.target.value });
                    }}
                    type="text"
                    value={inputs?.search}
                    style={{ minWidth: '250px' }}
                    placeholder={t('FILTER_CUSTOM_EMAIL')}
                  />
                </div>
                {Boolean(hasFilter) && (
                  <button
                    className="border rounded-0 ml-2 font-weight-700 font-montserrat text-xs pill-button text-maya-blue border-maya-blue bg-white"
                    type="button"
                    onClick={cleanFilters}
                  >
                    {t('PROFILES_CLEAR_FILTERS')}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FilterCustomEmails;
