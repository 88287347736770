import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { setCustomEmails } from '../redux/actions/customEmailsActions';
import { t } from '../i18n/i18n';
import useTranslations from './useTranslations';
import EditPencilIcon from '../assets/common/EditPencilIcon';
import { getCustomEmailsAll, getTemplateById } from '../API/services/alkymersService';

export const useCustomEmails = () => {
  const dispatch = useDispatch();
  const { key } = useTranslations();
  const [sendMail, setSendMail] = useState('');
  const [editMailVisible, setEditMailVisible] = useState(null);
  const [restartTemplate, setRestartTemplate] = useState(null);

  moment.updateLocale(key);
  const customEmails = useSelector((state) => state.customEmails.customEmailsPaginated);

  const getDefaultTemplate = async () => {
    const id = 'd-c9096b03a2c14af6b22551197e6130cf';
    const template = await getTemplateById(id);
    return template?.versions[0]?.html_content;
  };

  const translateEmail = (data, LABEL) => {
    try {
      return data?.translations[key][LABEL];
    } catch (error) {
      return LABEL;
    }
  };

  const mappingDataToRowTable = async (data) => {
    const defaultTemplate = await getDefaultTemplate();

    return data?.map((item, indexRow) => {
      const subjectTranslated = translateEmail(item, item?.subject);
      const isCustom = item?.companyId || item?.workspaceId;
      const newItem = {
        ...item,
        subjectTranslated,
        title: translateEmail(item, item?.title),
        typeColumn: !isCustom ? (
          <span title={t('DEFAULT_ALKEMY')}>{t('DEFAULT_EMAIL')}</span>
        ) : (
          <span title={t('CUSTOM_ALKEMY')}>{t('CUSTOM_EMAIL')}</span>
        ),
        sendTypeColumn:
          item?.sendType === 'MANUAL' ? (
            <span title={t('MANUAL_EMAIL')}>{t('MANUAL')}</span>
          ) : (
            <span title={t('AUTOMATIC_EMAIL')}>{t('AUTOMATIC')}</span>
          ),
      };
      return {
        ...newItem,

        actions: (
          <div
            className="d-flex py-3 flex-shrink-0 align-items-center  pl-2"
            style={{ width: '3.5rem' }}
          >
            <Button className="border-0 " onClick={() => setEditMailVisible(newItem)}>
              <EditPencilIcon />
            </Button>
            {isCustom && (
              <Button
                className="border-0 "
                onClick={() => {
                  setRestartTemplate(newItem);
                }}
                title={t('RESTART_LABEL')}
              >
                <FontAwesomeIcon icon={faUndoAlt} size="xl" color="grey" />
              </Button>
            )}
          </div>
        ),
      };
    });
  };

  const getCustomEmails = async (params) => {
    const { limit, offset, ...rest } = params;
    const response = await getCustomEmailsAll(params);
    const list = response?.data;
    const result = {
      list,
      filter: rest,
      pagination: { limit, offset },
    };
    dispatch(setCustomEmails(result));
    return result;
  };

  const getCustomEmailsForTable = async (filters) => {
    const { limit, offset, ...rest } = filters;

    dispatch(setCustomEmails({ ...customEmails, isLoading: true, reload: offset === 0 }));

    try {
      if (offset > 0 && Object.keys(rest).length > 1 && customEmails?.pagination?.endPagination) {
        dispatch(setCustomEmails({ ...customEmails, isLoading: false, reload: false }));
        return [];
      }

      const responseData = await getCustomEmailsAll({
        ...filters,
      });
      const collection = responseData?.collection;
      const pagination = responseData?.pagination;
      const tabsFilter = responseData?.extraData?.tabsFilter;

      const listWitoutRepeat = collection;

      const total =
        !offset && !filters?.customFilters?.length
          ? pagination?.total
          : customEmails?.pagination?.total;

      const totalTabs = !offset
        ? filters?.customFilters?.length
          ? customEmails?.tabsFilter
          : tabsFilter
        : customEmails?.tabsFilter;

      const listData = await mappingDataToRowTable(listWitoutRepeat);
      const result = {
        list: offset ? [...customEmails?.list, ...listData] : listData,
        filter: rest,
        pagination: {
          total,
          limit,
          offset,
          endPagination: !listWitoutRepeat?.length,
        },
        tabsFilter: totalTabs,
      };

      dispatch(setCustomEmails({ ...result, isLoading: false, reload: false }));
      return result;
    } catch (error) {
      dispatch(setCustomEmails({ isLoading: false, reload: false }));
    }
    return customEmails;
  };

  return {
    getCustomEmails,
    getCustomEmailsForTable,
    sendMail,
    setSendMail,
    editMailVisible,
    setEditMailVisible,
    restartTemplate,
    setRestartTemplate,
    translateEmail,
  };
};
