import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Modal, notification } from 'antd';
import { useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { t } from '../../i18n/i18n';
import {
  createComment,
  deleteComment,
  getInvitationComments,
} from '../../API/services/screeningService';
import {
  getUserCompanyComments,
  createUserCompanyComment,
  deleteUserCompanyComment,
} from '../../API/services/companyService';
import closeIcon from '../../assets/createSearch/close-icon.svg';
import './InvitedProfileComments.scss';
import DeleteIcon from '../../assets/profile/Delete';
import Collapsed from '../Collapsed/Collapsed';
import { nanoid } from 'nanoid';

const InvitedProfileComments = ({ className, isInvitedProfile }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState(0);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const [comments, setComments] = useState([]);
  const { recruitSessionId, id, profileId } = useParams();
  const { invitedProfile, profileInvitationsAndChallenges } = useSelector(
    (state) => state.profiles
  );
  const [otherCommentsLoading, setOtherCommentsLoading] = useState(false);

  const getCommentsFn = () => {
    if (isInvitedProfile && recruitSessionId) {
      return getInvitationComments(profileId, recruitSessionId, {
        order: 'DESC',
        orderBy: 'DATE',
      });
    }
    return getUserCompanyComments(profileId);
  };
  useEffect(() => {
    if (!invitedProfile) {
      setLoading(true);

      getCommentsFn().then((res) => {
        setComments(res?.collection || res);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [invitedProfile]);

  const handlePostComment = async () => {
    if (text.length > 100) {
      notification.open({
        message: t('NOTES_LIMIT_OF_CHARACTERS'),
        type: 'error',
      });
      return null;
    }

    const createCommentFn = () => {
      if (isInvitedProfile) {
        return createComment({ text, profileId, recruitSessionId });
      }
      return createUserCompanyComment(text, profileId);
    };

    await createCommentFn().then((res) => {
      if (!Object.keys(res).length) {
        notification.open({
          message: t('CREATE_COMMENT_ERROR'),
          type: 'error',
        });
      } else {
        notification.open({
          message: t('CREATE_COMMENT_SUCCESS'),
          type: 'success',
        });
        setComments([
          {
            createdAt: res.createdAt,
            text,
            id: res.id,
            partnerName: `${user?.firstName} ${user?.lastName}`,
            partnerId: user.id,
            authorEmail: user.email,
          },
          ...comments,
        ]);
        setText('');
      }
      setShowModal(false);
    });
  };

  const handleDeleteComment = async () => {
    const deleteCommentFn = isInvitedProfile ? deleteComment : deleteUserCompanyComment;
    await deleteCommentFn(selectedComment).then(() => {
      notification.open({
        message: t('DELETE_COMMENT_SUCCESS'),
        type: 'success',
      });
      setComments(comments?.filter((item) => item.id !== selectedComment));
      setSelectedComment(0);
    });
  };
  const [collapsedSelected, setCollapsedSelected] = useState([]);
  const [otherComments, setOtherComments] = useState([]);

  const fetchComments = async () => {
    setOtherCommentsLoading(true);
    if (profileInvitationsAndChallenges?.invitations?.length) {
      try {
        const allComments = await Promise.all(
          profileInvitationsAndChallenges.invitations.map(async (invitation) => {
            if (
              invitation?.recruitSessionId === invitedProfile?.recruitSession?.id ||
              !invitation.recruitSessionId
            )
              return null;
            const comments = await getInvitationComments(
              invitation.profileId,
              invitation?.recruitSessionId,
              {
                order: 'DESC',
                orderBy: 'DATE',
              }
            );
            if (comments?.collection?.length > 0) {
              return {
                comments: comments.collection,
                screeningName: invitation.screeningName,
              };
            }
            return null;
          })
        );

        const filteredComments = allComments.filter((comment) => comment !== null);
        setOtherComments(filteredComments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    }
    setOtherCommentsLoading(false);
  };

  return (
    <div
      className={`position-relative description-container  bg-white mx-auto notes-section b-radius-8 ${className}`}
    >
      <Modal
        className="screening-profile-comment-modal"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        closeIcon={<img src={closeIcon} alt={t('CLOSE_ALT')} />}
        footer={
          <button
            onClick={handlePostComment}
            className="rounded-pill font-weight-bold d-flex align-items-center border-0 outline-none px-4 py-2  text-white bg-maya-blue"
            type="button"
          >
            {t('SAVE_COMMENT_BUTTON')}
          </button>
        }
      >
        <textarea
          className="rounded p-2 outline-none w-100 mt-4 border comment-input-height  text-xs"
          placeholder={t('ADD_COMMENT_PLACEHOLDER')}
          value={text}
          onChange={(e) => {
            if (e.target.value.length < 255) setText(e.target.value);
          }}
          type="text"
          maxLength={255}
        />
      </Modal>
      <Modal
        visible={Boolean(selectedComment)}
        onOk={handleDeleteComment}
        onCancel={() => setSelectedComment(0)}
        okText={t('DELETE_COMMENT_MODAL_TEXT_CONFIRM')}
        cancelText={t('DELETE_COMMENT_MODAL_TEXT_CANCEL')}
      >
        <div className="p-3 invitation-status-modal-container">
          <span className="">{t('DELETE_COMMENT_MODAL_TEXT')}</span>
        </div>
      </Modal>
      <div className="d-flex flex-column mb-4 px-4 pt-3">
        <span className="font-weight-600 ">{t('INVITED_PROFILE_COMMENTS_TITLE')}</span>
        <span className="text-sm invited-profile-comment-text text-xs font-weight-500">
          {t('COMMENTS_SUBTITLE')}
        </span>
      </div>
      {loading ? (
        <Spinner show />
      ) : (
        <div className="d-flex flex-column">
          {!comments?.length ? (
            <EmptyComponent
              className="p-4"
              title={t('PROFILE_COMMENTS_EMPTY_STATE_TITLE')}
              subtitle={
                <button
                  onClick={() => setShowModal(true)}
                  type="button"
                  className="text-xs border-maya-blue cursor-pointer rounded-0 d-flex align-items-center font-weight-700 text-blue-principal bg-white notes-empty-state-button border-button"
                >
                  {t('ADD_PROFILE_NOTES')}
                </button>
              }
              subtitleClassname="text-center"
            />
          ) : (
            <div className="px-4 d-flex flex-column gap-1">
              {comments?.map((comment, i) => (
                <div className={`border-bottom d-flex flex-column ${i !== 0 && 'mt-4'}`}>
                  <div className="d-flex">
                    <span className=" font-weight-bold text-xs invited-profile-comment-title-data mr-2 text-xs">{`${
                      comment.partnerName
                        ? comment.partnerName
                        : `${comment.authorName} ${comment.authorLastname}`
                    } (${format(new Date(comment.createdAt), 'dd/MM/yyyy')})`}</span>
                    {user?.id === comment.partnerId || user?.id === comment.authorId ? (
                      <button
                        type="button"
                        onClick={() => setSelectedComment(comment?.id)}
                        className="invited-profile-delete-comment-button d-flex align-items-center outline-none"
                      >
                        <DeleteIcon fill="#4987be" />
                      </button>
                    ) : null}
                  </div>
                  <p className=" text-xs mt-2 invited-profile-comment-text text-xs font-weight-500">
                    {comment.text || comment.comment}
                  </p>
                </div>
              ))}
              <button
                onClick={() => setShowModal(true)}
                type="button"
                className="d-flex bg-transparent border-0 outline-none py-4"
              >
                <span className=" font-weight-600 text-xs text-blue-principal">
                  {t('ADD_PROFILE_NOTES')}
                </span>
              </button>
            </div>
          )}
          {[
            {
              title: t('COMMENTS_FROM_OTHER_EVALUATIONS'),
              content: (
                <div className="d-flex flex-column gap-2 pt-2 pb-4 b-radius-8">
                  {!otherCommentsLoading ? (
                    Boolean(otherComments?.length) ? (
                      otherComments?.map((groupOfComments, index) => (
                        <div className="py-1" key={nanoid()}>
                          <span className="font-weight-700 text-md color-blue-principal text-underline mb-2">
                            {groupOfComments?.screeningName}
                          </span>
                          <div className="d-flex flex-column gap-1">
                            {groupOfComments?.comments?.map((comment) => (
                              <div
                                className={`border-bottom-comment d-flex flex-column gap-2 pb-1`}
                                key={comment?.id}
                              >
                                <div className="d-flex justify-content-between w-100">
                                  <span className="font-montserrat font-weight-bold text-sm invited-profile-comment-title-data mr-2 text-xs">{`${
                                    comment.partnerName
                                      ? comment.partnerName
                                      : `${comment.authorName} ${comment.authorLastname}`
                                  } (${format(new Date(comment.createdAt), 'dd/MM/yyyy')})`}</span>
                                  {user?.id === comment.partnerId ||
                                  user?.id === comment.authorId ? (
                                    <button
                                      type="button"
                                      onClick={() => setSelectedComment(comment?.id)}
                                      className="invited-profile-delete-comment-button d-flex align-items-center outline-none"
                                    >
                                      <DeleteIcon fill="#D9D9D9" />
                                    </button>
                                  ) : null}
                                </div>
                                <p className="font-montserrat text-sm mt-1 invited-profile-comment-text text-xs font-weight-500">
                                  {comment.text || comment.comment}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    ) : (
                      <EmptyComponent
                        className="p-4"
                        title={t('PROFILE_OTHER_COMMENTS_EMPTY_STATE_TITLE')}
                      />
                    )
                  ) : (
                    <Spinner show />
                  )}
                </div>
              ),
              isVisible: true,
              collapsedEnabled: true,
            },
          ].map((item, index) => (
            <Collapsed
              collapsedSelected={collapsedSelected}
              setCollapsedSelected={setCollapsedSelected}
              index={index}
              options={item}
              collapsedEnabled={item.collapsedEnabled}
              containerClassname={'py-0 my-0 px-4 b-radius-8'}
              titleClassnames={
                'justify-content-end align-items-center gap-1 font-weight-400 text-xs text-muted py-2'
              }
              backgroundColor={'bg-blue-light'}
              maxHeight="20rem"
              onClick={fetchComments}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default InvitedProfileComments;
