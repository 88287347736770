import React, { useEffect, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import './App.css';
import 'antd/dist/antd.css';
import './bootstrap/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './index.scss';
import './padding.scss';
import './placeholder-loading.css';
import './table.scss';
import { useDispatch, useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  PrivateRoute,
  LoggedOutRoute,
  CheckToken,
  PublicRoute,
  FreeRoute,
} from './components/Auth/Auth';
import {
  HOME,
  SIGNIN,
  PROFILES,
  PROFILES_DETAIL,
  ERROR,
  PROFILE,
  SAVED_SEARCHES,
  SAVED_PROFILES,
  RESULTS,
  SCREENING,
  SCREENING_SEARCHES,
  SCREENING_SUCCESS,
  SEARCH_DASHBOARD,
  CREATE_TEMPLATE,
  SCREENINGS_PROFILE,
  INVITED_PROFILES_DETAIL,
  CREATE_TEST_GROUP,
  NEW_SEARCH_SEND,
  CREATE_ABM_TESTGROUP_SCREENING,
  EDIT_TEST_GROUP,
  INVITED_PROFILES_PROCTORING_DETAIL,
  TESTS,
  NEW_SEARCH_SUCCESS,
  RESET_TEST_GROUP,
  CREATE_EVALUATION,
  REGISTER,
  FORGOTPASSWORD,
  WORKSPACE,
  CREATED_WORKSPACE,
  CONFIGURATION,
  CONFIGURATION_MEMBERS,
  PLANS_CONSUMPTIONS,
  PLAN_PAYMENT_VALIDATE,
  PAYMENT_STATUS,
  PAYMENT_SUCCESS,
  PAYMENT_PENDING,
  PAYMENT_FAILURE,
  CREATE_JOB_POST,
  DETAIL_JOB_POST,
  VIEW_ALL_JOB_POST,
  EDIT_JOB_POST,
  CERTIFICATES,
  NOTIFICATIONS,
  CATEGORIES_TEST,
  ROLES_TESTGROUP,
  USERS_SUMMARY,
  CUSTOM_EMAILS,
} from './constants/routes';
import Searches from './pages/Searches/Searches';
import { determinateParameter } from './utils/determinateParameter';
import { auth0Client } from './auth0/auth0';
import Favorites from './pages/Favorites';
import { PageLoad } from './utils/filters/segmentUtils';
import SignIn from './pages/SignIn';
import ProfilesIndex from './pages/ProfilesIndex';
import CompanyProfile from './pages/CompanyProfile';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import MyError from './components/MyError/MyError';
import Error from './pages/Error/Error';
import useTranslations from './hooks/useTranslations';
import Results from './pages/Results';
import Screening from './pages/Screening/Screening';
import Certificates from './pages/Certificates/Certificates';
import ScreeningSearches from './pages/ScreeningSearches/ScreeningSearches';
import {
  SetAuthenticated,
  setCurrentWorkspace,
  setLoadingMe,
  setUserEmail,
  setUserToken,
  setVerify,
  setVerifyEmailUser,
} from './redux/actions/authActions';
import { Window } from './utils/window';
import Search from './pages/Search/Search';
import ScreeningSent from './pages/ScreeningSent/ScreeningSent';
import CreateTemplate from './pages/CreateTemplate/CreateTemplate';
import ScreeningProfile from './pages/ScreeningProfile/ScreeningProfile';
import InvitedProfile from './pages/InvitedProfile/InvitedProfile';
import useRoles from './hooks/useRoles';
import CreateTestGroup from './pages/CreateTestGroup/CreateTestGroup';
import NewSearchSend from './pages/NewSearchSend/NewSearchSend';
import Tests from './pages/Tests/Tests';
import useSidebar from './hooks/useSidebar';
import ABMScreeningsTestGroups from './pages/ABMScreeningsTestgroups/ABMScreeningsTestgroups';
import CandidateProctoringDetail from './pages/CandidateProctoringDetail/CantidateProctoringDetail';
import useHubspotIdentify from './hooks/useHubspotIdentify';
import useHubspotRoute from './hooks/useHubspotRoute';
import NewSearchSuccess from './pages/NewSearchSuccess/NewSearchSuccess';
import ResetTestgroups from './pages/ResetTestgroups/ResetTestgroups';
import CreateEvaluation from './pages/CreateEvaluation/CreateEvaluation';
import Workspace from './pages/Workspace/Workspace';
import CreatedWorkspace from './pages/CreatedWorkspace/CreatedWorkspace';
import MembersConfiguration from './pages/MembersConfiguration/MembersConfiguration';
import Plans from './pages/Plans/Plans.jsx';
import PaymentValidation from './pages/Plans/PaymentValidation/PaymentValidation.jsx';
import CreateJobPost from './pages/Jobs/CreateJobPost/CreateJobPost.js';
import Jobs from './pages/Jobs/Jobs.js';
import processQueryString from './utils/processQueryString';
import { acceptWorkspaceInvitationsBatch } from './API/services/companyService';
import Personalize from './pages/Settings/Personalize';
import { getLocalStorageWorkspace, setLocalStorageWorkspace } from './utils/workspaceUtils';
import EditJobPost from './pages/Jobs/EditJobPost/EditJobPost.js';
import DetailJobPost from './pages/Jobs/DetailJobPost/DetailJobPost.js';
import { PartnerWorkspaceRole } from './constants/enums.js';
import Success from './pages/Payments/Success.jsx';
import Pending from './pages/Payments/Pending.jsx';
import Failure from './pages/Payments/Failure.jsx';
import Status from './pages/Payments/Status.jsx';
import NotificationPage from './pages/Notification/NotificationPage.js';
import CategoriesPage from './pages/Categories/CategoriesPage.jsx';
import RolesTestGroupPage from './pages/RolesTestGroup/RolesTestGroupPage.jsx';
import UserSummary from './pages/UsersSummary/UsersSummary.jsx';
import CustomEmails from './pages/CustomEmails/CustomEmails.jsx';

const App = () => {
  const history = useHistory();
  let prevPath = '';
  const { language, key } = useTranslations();
  const [screeningTestCondition, setScreeningTestCondition] = useState(true);
  const [createTestGroupCondition, setCreateTestGroupCondition] = useState(true);
  const [resetTestGroupCondition, setResetTestGroupCondition] = useState(true);
  const { user, currentWorkspace, currentRole } = useSelector((state) => state.auth);
  const { roles } = useRoles();
  const { setCollapsed } = useSidebar();
  const dispatch = useDispatch();

  useHubspotIdentify();
  useHubspotRoute();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname !== prevPath) {
        if (determinateParameter(location.pathname)) {
          const splittedLocation = location.pathname.split('/');
          PageLoad(splittedLocation[1], { userId: splittedLocation[2], prevPath });
        } else {
          PageLoad(location.pathname, { prevPath });
        }
        prevPath = location.pathname;
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (Window()) {
      setCreateTestGroupCondition(
        JSON.parse(localStorage.getItem('roles'))?.includes(roles.TESTGROUPCREATOR)
      );
      setScreeningTestCondition(
        JSON.parse(localStorage.getItem('roles'))?.includes(roles.TESTSCREENINGSPARTNER)
      );
      setResetTestGroupCondition(
        JSON.parse(localStorage.getItem('roles'))?.includes(roles.TESTGROUPRESTARTER)
      );
      if (JSON.parse(localStorage.getItem('isOnboarding')) === true) {
        setCollapsed(false);
      }
      if (user && !getLocalStorageWorkspace() && user.workspaces.length !== 0) {
        let indexWorkspace;
        const workspaceId = Window().localStorage.getItem('workspaceIdRedirect');
        localStorage.removeItem('workspaceIdRedirect');
        if (workspaceId) {
          indexWorkspace = user.workspaces.findIndex(
            (workspace) => workspace.workspaceId === Number(workspaceId)
          );
        }
        if (indexWorkspace >= 0) {
          setLocalStorageWorkspace(user?.workspaces[indexWorkspace]?.workspace);
        } else {
          setLocalStorageWorkspace(user?.workspaces[0]?.workspace);
        }
      }
    }
  }, [user, currentWorkspace, currentRole]);

  useEffect(() => {
    if (Window()) {
      auth0Client().checkSession(
        {
          audience: process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE,
          scope: 'read:current_user',
        },
        (err, result) => {
          (async () => {
            if (err === null && result.accessToken) {
              localStorage.setItem('token', result.accessToken);
              localStorage.setItem(
                'roles',
                JSON.stringify(
                  result.idTokenPayload[`${process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE}roles`]
                )
              );
              dispatch(SetAuthenticated(true));
              dispatch(
                setVerify(
                  result.idTokenPayload[
                    `${process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE}email_verified`
                  ]
                )
              );
              dispatch(
                setVerifyEmailUser(
                  result.idTokenPayload[`${process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE}email`]
                )
              );
              dispatch(setUserToken(result.accessToken));
              dispatch(
                setUserEmail(
                  result.idTokenPayload[`${process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE}email`]
                )
              );
              if (
                JSON.parse(localStorage.getItem('isOnboarding')) === null &&
                result.idTokenPayload[
                  `${process.env.RAZZLE_RUNTIME_AUTH_ZERO_AUDIENCE}roles`
                ]?.includes(roles?.TESTSCREENINGSPARTNER)
              ) {
                setCollapsed(false);
                localStorage.setItem('isOnboarding', false);
              }

              await setWorkspace();

              dispatch(setLoadingMe(false));
            } else {
              dispatch(SetAuthenticated(false));
              dispatch(setLoadingMe(false));
              dispatch(setVerify(false));
            }
          })();
        }
      );
    }
  }, []);

  const setWorkspace = async () => {
    await acceptWorkspaceInvitationsBatch();
    const queryParams = processQueryString(history.location.search);
    const saveWorkspaceSelect = getLocalStorageWorkspace();

    dispatch(
      setCurrentWorkspace(
        !Number.isNaN(Number(queryParams.workspace))
          ? Number(queryParams.workspace)
          : saveWorkspaceSelect?.id
      )
    );
  };

  const isWorkspaceAdminOrOwner = [
    PartnerWorkspaceRole.ADMINISTRATOR,
    PartnerWorkspaceRole.OWNER,
  ].includes(getLocalStorageWorkspace()?.role);

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider messages={language} locale={key} onError={() => {}}>
        <CheckToken>
          <Switch className="switch-reset">
            <PrivateRoute exact path={HOME}>
              <Home />
            </PrivateRoute>
            <PrivateRoute exact path={WORKSPACE}>
              <Workspace />
            </PrivateRoute>
            <PrivateRoute exact path={CONFIGURATION}>
              <Personalize />
            </PrivateRoute>
            <PrivateRoute exact path={CONFIGURATION_MEMBERS}>
              <MembersConfiguration />
            </PrivateRoute>
            <PrivateRoute exact path={PLANS_CONSUMPTIONS}>
              <Plans />
            </PrivateRoute>
            <PrivateRoute exact path={PLAN_PAYMENT_VALIDATE}>
              <PaymentValidation />
            </PrivateRoute>
            <PrivateRoute exact path={PAYMENT_STATUS}>
              <Status />
            </PrivateRoute>
            <PrivateRoute exact path={PAYMENT_SUCCESS}>
              <Success />
            </PrivateRoute>
            <PrivateRoute exact path={PAYMENT_PENDING}>
              <Pending />
            </PrivateRoute>
            <PrivateRoute exact path={PAYMENT_FAILURE}>
              <Failure />
            </PrivateRoute>
            <PrivateRoute exact path={PROFILES_DETAIL}>
              <InvitedProfile isInvitedProfile={false} />
            </PrivateRoute>
            <PrivateRoute exact path={INVITED_PROFILES_DETAIL}>
              <InvitedProfile isInvitedProfile />
            </PrivateRoute>
            {process.env.RAZZLE_RUNTIME_IS_PROCTORING_ENABLED === 'true' && (
              <PrivateRoute exact path={INVITED_PROFILES_PROCTORING_DETAIL}>
                <CandidateProctoringDetail isInvitedProfile />
              </PrivateRoute>
            )}
            <PrivateRoute exact path={PROFILES}>
              <ProfilesIndex />
            </PrivateRoute>
            <PrivateRoute exact path={PROFILE}>
              <CompanyProfile />
            </PrivateRoute>
            <PrivateRoute exact path={NOTIFICATIONS}>
              <NotificationPage />
            </PrivateRoute>
            <PrivateRoute exact path={SCREENINGS_PROFILE}>
              <ScreeningProfile />
            </PrivateRoute>
            <PrivateRoute exact path={SAVED_SEARCHES}>
              <Searches />
            </PrivateRoute>
            <PrivateRoute exact path={SAVED_PROFILES}>
              <Favorites />
            </PrivateRoute>
            <PrivateRoute exact path={SCREENING}>
              <Screening />
            </PrivateRoute>
            <PrivateRoute exact path={SEARCH_DASHBOARD}>
              <Search />
            </PrivateRoute>
            <PrivateRoute exact path={TESTS}>
              <Tests />
            </PrivateRoute>
            {isWorkspaceAdminOrOwner && (
              <PrivateRoute exact path={USERS_SUMMARY}>
                <UserSummary />
              </PrivateRoute>
            )}
            {createTestGroupCondition && (
              <PrivateRoute exact path={CUSTOM_EMAILS}>
                <CustomEmails />
              </PrivateRoute>
            )}
            {createTestGroupCondition && (
              <PrivateRoute exact path={CREATE_TEST_GROUP}>
                <CreateTestGroup />
              </PrivateRoute>
            )}
            {createTestGroupCondition && (
              <PrivateRoute exact path={EDIT_TEST_GROUP}>
                <CreateTestGroup />
              </PrivateRoute>
            )}
            {createTestGroupCondition && (
              <PrivateRoute exact path={CREATE_ABM_TESTGROUP_SCREENING}>
                <ABMScreeningsTestGroups />
              </PrivateRoute>
            )}
            {createTestGroupCondition && (
              <PrivateRoute exact path={CATEGORIES_TEST}>
                <CategoriesPage />
              </PrivateRoute>
            )}
            {createTestGroupCondition && (
              <PrivateRoute exact path={ROLES_TESTGROUP}>
                <RolesTestGroupPage />
              </PrivateRoute>
            )}
            {screeningTestCondition && (
              <PrivateRoute exact path={SCREENING_SEARCHES}>
                <ScreeningSearches />
              </PrivateRoute>
            )}
            {screeningTestCondition && (
              <PrivateRoute exact path={CREATE_EVALUATION}>
                <CreateEvaluation />
              </PrivateRoute>
            )}
            {resetTestGroupCondition && (
              <PrivateRoute exact path={RESET_TEST_GROUP}>
                <ResetTestgroups />
              </PrivateRoute>
            )}
            <PrivateRoute exact path={NEW_SEARCH_SEND}>
              <NewSearchSend />
            </PrivateRoute>
            <PrivateRoute exact path={NEW_SEARCH_SUCCESS}>
              <NewSearchSuccess />
            </PrivateRoute>
            <PrivateRoute exact path={SCREENING_SUCCESS}>
              <ScreeningSent />
            </PrivateRoute>
            <PrivateRoute exact path={CREATE_TEMPLATE}>
              <CreateTemplate />
            </PrivateRoute>
            <PrivateRoute exact path={RESULTS}>
              <Results />
            </PrivateRoute>
            <PrivateRoute exact path={CREATED_WORKSPACE}>
              <CreatedWorkspace />
            </PrivateRoute>
            {isWorkspaceAdminOrOwner && (
              <PrivateRoute exact path={CREATE_JOB_POST}>
                <CreateJobPost />
              </PrivateRoute>
            )}
            {isWorkspaceAdminOrOwner && (
              <PrivateRoute exact path={EDIT_JOB_POST}>
                <EditJobPost />
              </PrivateRoute>
            )}
            <PrivateRoute exact path={VIEW_ALL_JOB_POST}>
              <Jobs />
            </PrivateRoute>
            <PrivateRoute exact path={DETAIL_JOB_POST}>
              <DetailJobPost />
            </PrivateRoute>
            <LoggedOutRoute exact path={SIGNIN}>
              <SignIn />
            </LoggedOutRoute>
            <LoggedOutRoute exact path={REGISTER}>
              <SignIn />
            </LoggedOutRoute>
            <LoggedOutRoute exact path={FORGOTPASSWORD}>
              <SignIn />
            </LoggedOutRoute>
            <FreeRoute exact path={`${CERTIFICATES}`}>
              <Certificates />
            </FreeRoute>
            <PublicRoute exact path={`${ERROR}`}>
              <Error />
            </PublicRoute>
            <PublicRoute exact path={`${ERROR}/:status`}>
              <MyError />
            </PublicRoute>
            <PublicRoute exact path="/*">
              <NotFound />
            </PublicRoute>
          </Switch>
        </CheckToken>
      </IntlProvider>
    </QueryClientProvider>
  );
};

export default App;
